import useAppContext from "@/src/components/appContext/useAppContext";
import { FeatureToggle } from "@/src/constants/featureFlags";

export function useFeatureFlag() {
  const { ctx } = useAppContext();

  const isEnabled = (key: FeatureToggle) =>
    ctx?.featureFlags ? ctx.featureFlags[key] : false;

  return {
    isApiDebugEnabled: isEnabled(FeatureToggle.EnableApiDebug),
    isUIDebugEnabled: isEnabled(FeatureToggle.EnableUiDebug),
    isImageEnrichmentEnabled: isEnabled(FeatureToggle.ImageEnrichment),
    isTemplateCustomAttributeEnabled: isEnabled(
      FeatureToggle.TemplateCustomAttribute
    ),
    isVariantableCustomAttributeEnabled: isEnabled(
      FeatureToggle.VariantableCustomAttribute
    ),
    isActivityHistoryEnabled: isEnabled(FeatureToggle.ActivityHistory),
    isUrlUploadEnabled: isEnabled(FeatureToggle.UrlUpload),
    isPdpAssetsViewEnabled: isEnabled(FeatureToggle.PdpAssetsView),
    isViewImageDetailEnabled: isEnabled(FeatureToggle.ViewImageDetail),
    isEnrichmentApprovalEnabled: isEnabled(FeatureToggle.EnrichmentApproval),
  };
}
